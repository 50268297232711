
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';

export default defineComponent({
  name: 'Maintenance',
  components: {
    Button,
  },
});
